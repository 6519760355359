import React from 'react'

import { HeaderProvider } from './Header'
import { MenuProvider } from './Menu'
import { ScrollProvider } from './Scroll'

export const AppProvider: React.FC = ({ children }) => (
  <ScrollProvider>
    <HeaderProvider>
      <MenuProvider>
        {children}
      </MenuProvider>
    </HeaderProvider>
  </ScrollProvider>
)
