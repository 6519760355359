import React, { createContext, useContext, useMemo } from 'react'

import { ScrollDirection } from '../enums'
import { useScroll } from '../hooks'

const TIMEOUT = 150

interface ContextProps {
  scrollTop: number
  previousScrollTop: number
  time: number
  amountScrolled: number
  direction: string
  velocity: number
}
export const ScrollContext = createContext<ContextProps>({
  scrollTop: 0,
  previousScrollTop: 0,
  time: TIMEOUT,
  amountScrolled: 0,
  direction: 'same',
  velocity: 0,
})

export const ScrollProvider: React.FC = ({ children }) => {
  const { scrollTop, previousScrollTop, time } = useScroll(TIMEOUT)

  const amountScrolled = useMemo(() => (
    scrollTop - previousScrollTop
  ), [scrollTop, previousScrollTop])

  const direction = useMemo(() => {
    if (amountScrolled < 0) {
      return ScrollDirection.Up
    } else if (amountScrolled > 0) {
      return ScrollDirection.Down
    }
    return ScrollDirection.Same
  }, [amountScrolled])

  const velocity = useMemo(() => (
    Math.abs(amountScrolled / time)
  ), [amountScrolled, time])

  const value = useMemo(() => ({
    scrollTop,
    previousScrollTop,
    time,
    amountScrolled,
    direction,
    velocity,
  }), [
    scrollTop,
    previousScrollTop,
    time,
    amountScrolled,
    direction,
    velocity,
  ])

  return (
    <ScrollContext.Provider value={value}>
      {children}
    </ScrollContext.Provider>
  )
}

export const useScrollContext = (): ContextProps => useContext(ScrollContext)
