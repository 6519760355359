import { useEffect, useMemo, useRef, useState } from 'react'
import throttle from 'lodash.throttle'

import { isClient } from '../utils'

export const useScroll = (timeout = 250): ScrollData => {
  const defaultScrollTop = useMemo(() => {
    if (isClient()) {
      return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    }
    return 0
  }, [])
  const previousScrollTop = useRef(defaultScrollTop)
  const [currentScrollTop, setCurrentScrollTop] = useState(defaultScrollTop)

  useEffect(() => {
    const handleDocumentScroll = () => {
      const { scrollTop } = document.documentElement || document.body
      setCurrentScrollTop(scrollTop)
      previousScrollTop.current = scrollTop
    }

    const handleDocumentScrollThrottled = throttle(handleDocumentScroll, timeout)

    window.addEventListener('scroll', handleDocumentScrollThrottled)

    return () =>
      window.removeEventListener('scroll', handleDocumentScrollThrottled)
  }, [timeout])

  return {
    scrollTop: currentScrollTop,
    previousScrollTop: previousScrollTop.current,
    time: timeout,
  }
}
