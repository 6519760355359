import React, { createContext, useContext, useEffect, useMemo } from 'react'

import { ScrollDirection } from '../enums'
import { useVisibility } from '../hooks'
import { useScrollContext } from './Scroll'

const TOP_START = 100 // how many px from the top of the page should the show/hide behaviour start

interface ContextProps {
  isVisible: boolean
  isOnTopOfPage: boolean
}
export const HeaderContext = createContext<ContextProps>({
  isVisible: true,
  isOnTopOfPage: true,
})

export const HeaderProvider: React.FC = ({ children }) => {
  const { isVisible, show, hide } = useVisibility(true)
  const { scrollTop, direction } = useScrollContext()

  useEffect(() => {
    const shouldShow = scrollTop <= TOP_START || direction !== ScrollDirection.Down
    const shouldHide = (
      scrollTop > TOP_START &&
      direction === ScrollDirection.Down
    )
    if (shouldShow) {
      show()
    } else if (shouldHide) {
      hide()
    }
  }, [scrollTop, direction, hide, show])

  const isOnTopOfPage = useMemo(() => scrollTop === 0, [scrollTop])

  const value = useMemo(() => ({
    isVisible,
    isOnTopOfPage,
  }), [
    isVisible,
    isOnTopOfPage,
  ])

  return (
    <HeaderContext.Provider value={value}>
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeaderContext = (): ContextProps => useContext(HeaderContext)
