export enum ArrowDirection {
  Down = 'down',
  Left = 'left',
  Right = 'right',
  Up = 'up',
}

export enum SectionType {
  Client = 'client',
  CaseStudy = 'case-study',
  Lead = 'lead',
  Service = 'service',
  TeamMember = 'team-member',
  Technology = 'technology',
  Review = 'review',
}

export enum ContactElement {
  ContactSection = 'contact-section',
}

export enum ContactHref {
  IndexPage = '/#contact-section',
}

export enum HeadingTag {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export enum CommentLoadMode {
  Click = 'click',
  Default = 'default',
  Scroll = 'scroll',
}

export enum CommentCountMode {
  Number = 'number',
}

export enum ScrollDirection {
  Down = 'down',
  Same = 'same',
  Up = 'up',
}

export enum SeoType {
  Article = 'article',
  Profile = 'profile',
  Website = 'website',
}

export enum Order {
  Asc = 'asc',
  Desc = 'desc',
}

export enum ClutchWidgetType {
  XSmall = '1',
  Small = '2',
  Medium = '3',
  Large = '4',
  XLarge = '5',
}
