import { useEffect } from 'react'

import { ENUMERATED_DATA_ATTR, ENUMERATED_ELEMENT_CLASS } from '../constants'

const defaultRenderer = (n: number) => `${n < 10 ? 0 : ''}${n}/`
// to enumerate all `ENUMERATED_ELEMENT_CLASS` elements inside the content,
// they first need to get rendered
// after that, the `ENUMERATED_DATA_ATTR` attribute is set based on the element's index
// the `attr` css function can then used to render
// the `ENUMERATED_DATA_ATTR` attribute in `:before content`
export const useEnumerated = (
  elementClass: string = ENUMERATED_ELEMENT_CLASS,
  dataAttr: string = ENUMERATED_DATA_ATTR,
  renderer: (n: number) => string = defaultRenderer,
): void => {
  useEffect(() => {
    Array.from(document.getElementsByClassName(elementClass))
      .forEach((element, index) => {
        element.setAttribute(dataAttr, renderer(index + 1))
      })
  }, [elementClass, dataAttr, renderer])
}
