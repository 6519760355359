// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-page-components-blog-article-index-tsx": () => import("./../src/page-components/BlogArticle/index.tsx" /* webpackChunkName: "component---src-page-components-blog-article-index-tsx" */),
  "component---src-page-components-case-study-index-tsx": () => import("./../src/page-components/CaseStudy/index.tsx" /* webpackChunkName: "component---src-page-components-case-study-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-what-we-do-tsx": () => import("./../src/pages/what-we-do.tsx" /* webpackChunkName: "component---src-pages-what-we-do-tsx" */),
  "component---src-pages-who-we-are-tsx": () => import("./../src/pages/who-we-are.tsx" /* webpackChunkName: "component---src-pages-who-we-are-tsx" */)
}

