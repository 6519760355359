import { useEffect, useState } from 'react'

import { TOC_ANCHOR_CLASS, TOC_DATA_TEXT_ATTR } from '../constants'

export const useToc = (
  anchorClass: string = TOC_ANCHOR_CLASS,
  dataTextAttr: string = TOC_DATA_TEXT_ATTR,
): TocElement[] => {
  const [toc, setToc] = useState<TocElement[]>([])

  useEffect(() => {
    const list = Array.from(document.getElementsByClassName(anchorClass)).map(tocAnchor => {
      const to = tocAnchor.getAttribute('name') || ''
      return {
        text: tocAnchor.getAttribute(dataTextAttr) || to || '',
        to,
      }
    })
    setToc(list)

    return () => {
      setToc([])
    }
  }, [anchorClass, dataTextAttr])

  return toc
}
