import React, { createContext, useCallback, useContext, useMemo, PropsWithChildren } from 'react'

import { noopFunction } from '../constants'
import { usePagination } from '../hooks'

interface ContextProps {
  currentPage: number
  numOfPages: number
  maxToShowAll?: number
  setPage: SetPage
  nextPage: VoidFunction
  previousPage: VoidFunction
  pageItems: unknown[]
  onPageClick: SetPage
  onNextClick: VoidFunction
  onPreviousClick: VoidFunction
}

export const PaginationContext = createContext<ContextProps>({
  currentPage: 0,
  numOfPages: 0,
  maxToShowAll: 0,
  setPage: noopFunction,
  nextPage: noopFunction,
  previousPage: noopFunction,
  pageItems: [],
  onPageClick: noopFunction,
  onNextClick: noopFunction,
  onPreviousClick: noopFunction,
})

interface Props<T> {
  items: T[]
  itemsPerPage?: number
  maxToShowAll?: number
  onPageClick?: SetPage
  onNextClick?: VoidFunction
  onPreviousClick?: VoidFunction
}

export const PaginationProvider = function<T> ({
  items,
  itemsPerPage,
  maxToShowAll,
  onPageClick,
  onNextClick,
  onPreviousClick,
  children,
}: PropsWithChildren<Props<T>>): React.ReactNode {
  const {
    currentPage,
    numOfPages,
    setPage,
    nextPage,
    previousPage,
    pageItems,
  } = usePagination<T>(items, itemsPerPage)

  const pageClicked = useCallback((page: number) => {
    setPage(page)
    if (onPageClick) {
      onPageClick(page)
    }
  }, [setPage, onPageClick])

  const nextClicked = useCallback(() => {
    nextPage()
    if (onNextClick) {
      onNextClick()
    }
  }, [nextPage, onNextClick])

  const previousClicked = useCallback(() => {
    previousPage()
    if (onPreviousClick) {
      onPreviousClick()
    }
  }, [previousPage, onPreviousClick])

  const value = useMemo(() => ({
    currentPage,
    numOfPages,
    maxToShowAll,
    setPage,
    nextPage,
    previousPage,
    pageItems,
    onPageClick: pageClicked,
    onNextClick: nextClicked,
    onPreviousClick: previousClicked,
  }), [
    currentPage,
    numOfPages,
    maxToShowAll,
    setPage,
    nextPage,
    previousPage,
    pageItems,
    pageClicked,
    nextClicked,
    previousClicked,
  ])

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  )
}

export const usePaginationContext = (): ContextProps => useContext(PaginationContext)
