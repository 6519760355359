import { useStaticQuery, graphql } from 'gatsby'

export const useSiteMetadata = (): SiteMetadata => {
  const { site } = useStaticQuery<{ site: Site }>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image
            siteUrl
          }
        }
      }
    `,
  )

  return site.siteMetadata
}
