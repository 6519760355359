import moment from 'moment'
import Url from 'url-parse'

import { Order } from '../enums'

const SITE_URL = process.env.SITE_URL || ''

const formatDate = (date: Date): string =>
  moment(date).format('MMM DD, YYYY.')

const isClient = () => typeof window !== 'undefined'

const getInternalPath = (url: string): string | null => {
  if (url.startsWith('/') && !url.startsWith('//')) {
    return url
  }
  try {
    const parsedUrl = new Url(url)
    if (SITE_URL.startsWith(parsedUrl.origin)) {
      return `${parsedUrl.pathname}${parsedUrl.query.toString()}${parsedUrl.hash}`
    }
  } catch (err) {
    // swallow error
  }
  // external path
  return null
}

const range = (to: number) => Array(to).fill(1).map((num: number, index) => num + index)

const sortByNumber = (order: Order = Order.Asc) => (a: number, b: number): number => {
  let value = 1
  if (a < b) {
    value = -1
  }

  return order === Order.Asc ? value : value * -1
}
const sortByNumberAsc = sortByNumber()
const sortByNumberDesc = sortByNumber(Order.Desc)

export {
  formatDate,
  getInternalPath,
  isClient,
  range,
  sortByNumberAsc,
  sortByNumberDesc,
}
