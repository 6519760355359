import { useCallback, useMemo, useState } from 'react'

export const usePagination = function<T> (allItems: T[], itemsPerPage = 4): PaginationData<T> {
  const [currentPage, setCurrentPage] = useState(allItems.length > 0 ? 1 : 0)

  const numOfPages = useMemo(() => (
    Math.ceil(allItems.length / itemsPerPage)
  ), [allItems.length, itemsPerPage])

  const previousPage = useCallback(() => {
    setCurrentPage(page => Math.max(1, page - 1))
  }, [])

  const nextPage = useCallback(() => {
    setCurrentPage(page => Math.min(numOfPages, page + 1))
  }, [numOfPages])

  const setPage = useCallback((page: number) => {
    setCurrentPage(Math.min(Math.max(1, page), numOfPages))
  }, [numOfPages])

  const pageItems = useMemo(() => {
    const startIndex = itemsPerPage * (currentPage - 1)
    return allItems.slice(startIndex, startIndex + itemsPerPage)
  }, [itemsPerPage, currentPage, allItems])

  return {
    currentPage,
    pageItems,
    nextPage,
    numOfPages,
    previousPage,
    setPage,
  }
}
