import React, { createContext, useMemo, useContext } from 'react'

import { noopFunction } from '../constants'
import { useVisibility } from '../hooks'

interface ContextProps {
  isVisible: boolean
  show: VoidFunction
  hide: VoidFunction
  toggle: VoidFunction
}
export const MenuContext = createContext<ContextProps>({
  isVisible: false,
  show: noopFunction,
  hide: noopFunction,
  toggle: noopFunction,
})

export const MenuProvider: React.FC = ({ children }) => {
  const { isVisible, show, hide, toggle } = useVisibility(false)

  const value = useMemo(() => ({
    isVisible,
    show,
    hide,
    toggle,
  }), [
    isVisible,
    show,
    hide,
    toggle,
  ])

  return (
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  )
}

export const useMenuContext = (): ContextProps => useContext(MenuContext)
